<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and legend -->
      <h4 class="card-title mb-50">
        Estadísticas de consumo
      </h4>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="scatter"
        height="400"
        :options="apexChatData.scatterChart.chartOptions"
        :series="apexChatData.scatterChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      apexChatData,
    }
  },
}
</script>
